import './App.css';
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Home from './components/home/Home';
import Certifications from './components/certifications/Certifications';
import Navbar from "./components/navBar/NavBar";
import Footer from './components/footer/Footer';

function App() {
    return (
            <Router>
                <div className="App" style={{
                    maxWidth: '1400px',
                    margin: '0 auto'
                    }}>
                    <div className="fixed_top_container">
                        <Navbar/>
                    </div>
                    <div className="main_container">
                        <Routes>
                            <Route path='/' element={<Home/>} />
                            <Route path='/certifications' element={<Certifications/>} />
                        </Routes>
                    </div>
                    <div className="fixed_bottom_container">
                        <Footer/>
                    </div>
                </div>
            </Router>
    )
}

export default App;
