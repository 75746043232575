import React from 'react'
import CertList from './CertList'

function Certifications() {

    return (
        <div className="certificationsList">
            <div className="certifications-heading">
                <h1>Recent Certifications</h1>
            </div>
            <div className="certifications-card-list-container">
                <CertList/>
            </div>
        </div>
    )
}

export default Certifications