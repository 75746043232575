import React from "react";

function AboutMe() {

    return (
      <div className="about-me-container">
        <h2 className="about-me-title">About Me</h2>
        <p className="about-me-text">Hi, I'm a Full Stack Developer with professional IT experience in the areas of systems deployment, software solution delivery and operational support. Additional background in architectural design and project management.</p>
      </div>
    );
}

export default AboutMe;
