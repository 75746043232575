import React from 'react'
import Banner from '../banner/Banner';
import Projects from '../projects/Projects';
import Skills from '../skills/Skills';

function Home() {
    return (
        <div> 
            <Banner/>
            <Projects/>
            <Skills/>
        </div>
    )
}

export default Home