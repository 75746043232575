import React from "react";

function Intro() {

  return (
    <div className="intro-container">
      <div className="intro-text">
        <h1 className="intro-text-first-name">CHARLES GARRAWAY</h1>
        <h2 className="intro-text-portfolio">Web Development Portfolio</h2>
      </div>

    </div>
  );
}

export default Intro;
