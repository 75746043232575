import React from "react";
import { useNavigate, useLocation } from "react-router-dom";


function Navbar() {
    const navigate = useNavigate()
    const location = useLocation()

    function handleCerts() {
        navigate('/certifications')
        window.scrollTo(0, 0);
    }

    function handleHome() {
        navigate('/')
        window.scrollTo(0, 0);
    }

    return (
        <div className="navBar">
            <div className="contact-me">
                <a href="mailto:cgar17@gmail.com" target="_blank" rel="noreferrer">EMAIL</a>
            </div>
            {
                location.pathname === "/" ?
                <div className="certifications" onClick={handleCerts}>
                    <p>CERTIFICATIONS</p>
                </div>
                :
                <div className="certifications" onClick={handleHome}>
                    <p>HOME</p>
                </div>
            }
            
        </div>
        
    );
}

export default Navbar;
