import React from "react";

function Skills() {
  const skills = [
    'HTML / CSS',
    'JavaScript',
    'Node.JS',
    'React.JS',
    'Redux',
    'Python',
    'Git / GitHub',
    'Material UI',
    'PostgreSQL',
    'MongoDB',
    'NGINX',
    'Docker',
  ]
  
  return (
    <div>
      <h1 className="skills-heading">Technical Skills</h1>
      <div className="skills-grid">
        { skills.map((skill, index) => {
            return (
                <div key={index} className="skill"> 
                    <p>{skill}</p>
                </div>
            )
        })}
      </div>
    </div>
  )
}

export default Skills;
