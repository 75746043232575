import React from 'react'

function CertCourse({certCourseData}) {

    return (
        <div className="cert-course" >
            <h4>{certCourseData.title}</h4>
            {/* <p>{certCourseData.description}</p> */}
            <a href={certCourseData.certURL} target="_blank" rel="noreferrer">Certificate Link</a>
            <p></p>
            <img className="cert-image" src={require(`${certCourseData.image}`)} width={'100%'} alt=""/>
        </div>

    )
}

export default CertCourse