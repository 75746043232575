import React from 'react'
import CertCard from './CertCard'

function CertList() {

    const automationCourses =   [
        {
            title: 'Automating Real-World Tasks with Python',
            image: './cert_images/automating.webp',
            certURL: 'https://coursera.org/share/1f75168f7322c4243a136f36dfe36d2c',
            description: 'Automating Real-World Tasks with Python'
        },
        {
            title: 'Configuration Management and the Cloud',
            image: './cert_images/cloud.webp',
            certURL: 'https://coursera.org/share/d97dabf2d8bef907fcb3ed65a96f26c5',
            description: 'Configuration Management and the Cloud'
        },
        {
            title: 'Troubleshooting and Debugging Techniques',
            image: './cert_images/troubleshooting.webp',
            certURL: 'https://coursera.org/share/1a041fc5f39f4729b5bf08b5828665f1',
            description: 'Troubleshooting and Debugging Techniques'
        },
        {
            title: 'Introduction to Git and GitHub',
            image: './cert_images/git.webp',
            certURL: 'https://coursera.org/share/b098a8606dcb81dd92516c5ee138f290',
            description: 'Introduction to Git and GitHub'
        },
        {
            title: 'Using Python to Interact with the Operating System',
            image: './cert_images/operatingSystem.webp',
            certURL: 'https://coursera.org/share/d2fa8ca4fdded5f287a6e742587dd634',
            description: 'Using Python to Interact with the Operating System'
        },
        {
            title: 'Crash Course on Python',
            image: './cert_images/crashCourse.webp',
            certURL: 'https://coursera.org/share/a6a3c1dc5ca7637690003d610af50573',
            description: 'Crash Course on Python'
        }
    ]

    const certificationsData = [
        {
            title: 'Google IT Automation with Python',
            image: './cert_images/ItAutomation.webp',
            certURL: 'https://coursera.org/share/f676064aea4cca37fd43a9623a97b6f1',
            description: 'This six-course certificate, developed by Google, is designed to provide IT professionals with in-demand skills -- including Python, Git, Linux, Bash and IT automation -- that can help them advance their careers.',
            courses: automationCourses
        },
        {
            title: 'Full-Stack Engineer Career Path',
            image: './cert_images/fullStack.webp',
            certURL: 'https://www.codecademy.com/profiles/charlesGarraway8276255187/certificates/5f7e644d833c070013ef47c4',
            description: 'The Full-Stack Engineering Career Path, includes courses in Web Development Foundations, Building Interactive Websites, Front-End Development, Back-End Development, Full-Stack Development as well as relational databases.',
            courses: ''
        },
        {
            title: 'Learn How To Code',
            image: './cert_images/ltc.webp',
            certURL: 'https://www.codecademy.com/profiles/charlesGarraway8276255187/certificates/11a686a7fd57b8c214f7f92749388d42',
            description: 'This course teaches the basics of programming (not language specific).',
            courses: ''
        },
        {
            title: 'Code Foundations Skill Path',
            image: './cert_images/codeFoundations.webp',
            certURL: 'https://www.codecademy.com/profiles/charlesGarraway8276255187/certificates/5b55e668646caa552f8e4d1d',
            description: 'This path provides an overview of the main branches of programming: computer science, web development, and data science. It teaches important concepts you will find in every coding language, such as variables, functions, and control flow.',
            courses: ''
        }
    ]

    return (
        <div className="certification-card-list">
            { certificationsData.map((cert, index) => {
                return (
                    <div key={index}>
                        <CertCard
                            certData = {cert}
                        />
                    </div>
                )
            })}
        </div>
    )
}

export default CertList