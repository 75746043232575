import React from "react";
import Intro from "./Intro";
import AboutMe from "./AboutMe";

function Banner() {
    return (
        <div className="banner-container">
            <Intro/>
            <AboutMe/>
        </div>
    );
}

export default Banner;
