import React from "react";
import ProjectList from "./ProjectList";

function Projects() {
    return (
        <div className="projects">
            <div className="projects-heading">
                <h1>My Sample Projects</h1>
                <p>(Visit repositories for technical project details.)</p>
            </div>
            <div className="project-card-list-container">
                <ProjectList/>
            </div>
        </div>
    );
}

export default Projects;
