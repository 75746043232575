import React, { useState, useEffect } from 'react';
import AOS from "aos";
import { useLocation } from "react-router-dom";

function Footer() {
    const location = useLocation()
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(()=> {
        AOS.init({duration: 500, /* once: true */})
    },[])

    useEffect(() => {
        const checkScrollPosition = () => {
            if (window.scrollY > 200 && location.pathname === '/') {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', checkScrollPosition);
        return () => {
            window.removeEventListener('scroll', checkScrollPosition);
        };

        
    }, [location.pathname]);

    return (
        <div>
            {isScrolled && <div className="footer" data-aos="fade-up" data-aos-duration="1000" >
                <p className="signature" >C H A R L E S &nbsp;&nbsp;&nbsp;&nbsp; G A R R A W A Y</p>
            </div>}
        </div>
    );
}

export default Footer;
