import React from "react";
import ProjectCard from "./ProjectCard";

function ProjectList() {
    const projectsData = [
        {
            title: 'CG Sudoku',
            image: './images/sudoku.webp',
            description: 'A classic Sudoku Web App',
            notes: '',
            technology: {
                stack: 'Static',
                repository: ['GitHub'],
                thirdPartyApis: ['None'],
                other: ['ReactJS', 'MaterialUI', 'Redux'],
                hosts: [
                    {
                        name: 'Self Hosted',
                        apps: ['Static Site']
                    },
                ]
            },
            siteURL: 'https://sudoku.cgprojects.dev/',
            repositoryURL: 'https://github.com/c-garraway/cg-sudoku'
        },
        {
            title: 'Team Trivia',
            image: './images/trivia.webp',
            description: 'A fun Team Trivia competition Full Stack Web App',
            notes: '',
            technology: {
                stack: 'Full Stack (MERN)',
                repository: ['GitHub', 'DockerHub'],
                thirdPartyApis: ['https://the-trivia-api.com/'],
                other: ['ReactJS', 'MaterialUI', 'Redux', 'Node', 'Express', 'BCrypt', 'Passport', 'Docker'],
                hosts: [
                    {
                        name: 'Self Hosted',
                        apps: ['Web Service, ', 'Static Site', 'MongoDB']
                    },
                ]
            },
            siteURL: 'https://trivia.cgprojects.dev/',
            repositoryURL: 'https://github.com/c-garraway/trivia-client'
        },
        {
            title: 'BEM',
            image: './images/bem.webp',
            description: 'A Business Entity Management Full Stack Web App',
            notes: '',
            technology: {
                stack: 'Full Stack (PERN)',
                repository: ['GitHub', 'DockerHub'],
                thirdPartyApis: ['None'],
                other: ['ReactJS', 'MaterialUI', 'Redux', 'Node', 'Express', 'BCrypt', 'Passport', 'Docker', 'Google Oauth2 '],
                hosts: [
                    {
                        name: 'Self Hosted',
                        apps: ['Web Service, ', 'Static Site', 'PostgreSQL']
                    },
                ]
            },
            siteURL: 'https://bem.cgprojects.dev/',
            repositoryURL: 'https://github.com/c-garraway/bem-client'
        },
        {
            title: 'Random Online Store',
            image: './images/ros.webp',
            description: 'A eCommerce Full Stack Web App',
            notes: 'Note: The web service for this app may take up to 30 seconds to respond to initial request as a consequence of free tier hosting.',
            technology: {
                stack: 'Full Stack (PERN)',
                repository: ['GitHub', 'DockerHub'],
                thirdPartyApis: ['None'],
                other: ['ReactJS', 'Redux', 'Node', 'Express', 'BCrypt', 'Passport', 'Docker'],
                hosts: [
                    {
                        name: 'Self Hosted',
                        apps: ['Web Service, ', 'Static Site', 'PostgreSQL']
                    },
                ]
            },
            siteURL: 'https://ros.cgprojects.dev/',
            repositoryURL: 'https://github.com/c-garraway/ecom_frontend'
        },
        {
            title: 'Simple Twenty One',
            image: './images/sto.webp',
            description: 'A  version of the classic Twenty One card game.',
            notes: '',
            technology: {
                stack: 'Static Site',
                repository: ['GitHub'],
                thirdPartyApis: ['deckofcards-api, ', 'dadjokes-api'],
                other: ['HTML', 'CSS', 'JS'],
                hosts: [
                    {
                        name: 'Self Hosted',
                        apps: ['Static Site']
                    },                
                ]
            },
            siteURL: 'https://sto.cgprojects.dev/',
            repositoryURL: 'https://github.com/c-garraway/simpleTwentyOne'
        },
        
    ]
    return (
        <div className="project-card-list">
            { projectsData.map((project, index) => {
                return (
                    <div key={index}>
                        <ProjectCard
                            projectData = {project}
                        />
                    </div>
                )
            })}
        </div>
    );
}

export default ProjectList;
