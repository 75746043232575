import React, { useState, useEffect } from 'react'
import CertCourse from './CertCourse'
import AOS from "aos";

function CertCard({certData}) {

    const [displayCourses, setDisplayCourses] = useState(false)

    useEffect(()=> {
        AOS.init({duration: 500, once: true})
    },[])

    function handleOpenCourses() {
        setDisplayCourses(current => !current)
    }

    return (
        <div className="cert-card"/*  data-aos="fade-up" data-aos-duration="500" */>
            <h4>{certData.title}</h4>
            <p>{certData.description}</p>
            <a href={certData.certURL} target="_blank" rel="noreferrer">Certificate Link</a>
            <p></p>
            <img className="cert-image" src={require(`${certData.image}`)} width={'100%'} alt=""/>
            {
                certData.courses ?
                <p className='individual-course' onClick={handleOpenCourses}>{displayCourses ? 'CLOSE ' : 'OPEN ' }COURSE CERTIFICATES</p>
                : ''
                
            }
            {
                certData.courses ? 
                certData.courses.map((cert, index) => {
                    return (
                        <div> 
                            
                            {
                                displayCourses ? 
                                <div key={index}/*  data-aos="fade-up" data-aos-duration="200" */>
                                    <CertCourse
                                        certCourseData = {cert}
                                />
                                </div>
                                : ''
                            }
                        </div>
                    )
                })
                : ''
            }
            {
                certData.courses && displayCourses ?
                <p className='individual-course' onClick={handleOpenCourses}>CLOSE COURSE CERTIFICATES</p>
                : ''
                
            }
        </div>

    )
}

export default CertCard