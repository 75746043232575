import React, { useEffect } from "react";
import AOS from "aos";
import 'aos/dist/aos.css'

function ProjectCard({projectData}) {

    useEffect(()=> {
        AOS.init({duration: 500, /* once: true */})
    },[])

    return (
        <div className="project-card" data-aos="fade-up" >
            <div className="project-card-header">
                <h2 className="project-title">{projectData.title}</h2>
                <p className="project-description">{projectData.description}</p>
            </div>
            <div className="project-card-image">
                <img className="project-image" src={require(`${projectData.image}`)} width={'100%'} height={238} alt=""/>
            </div>
            <div className="project-card-footer">           
                <div className="project-button-container">
                    <button 
                        className="project-button"
                        type="button"
                        onClick={() => window.open(projectData.repositoryURL, '_blank')}
                        >
                        Visit Repository
                    </button>
                    <button 
                        className="project-button"
                        type="button"
                        onClick={() => window.open(projectData.siteURL, '_blank')}
                        >
                        Visit Site
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ProjectCard;
